import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";
import { useAnalytics } from "../../providers/AnalyticsProvider";
import Box from "../Box";
import Button from "../Button";
import Flex from "../Flex";

export const CookiesBanner = () => {
  const { t } = useTranslation("common");
  const { cookies, allowCookies, disableCookies } = useAnalytics();

  const now = new Date().getTime();

  if (cookies.hideUntil >= now) return null;

  return (
    <Container flexDirection={{ _: "column", md: "row"}}>
      <Box>{t("cookiesInfo")}</Box>
      <Flex ml={{_: 0, md: 6 }} mt={{ _: 3, md: 0 }}>
        <Button variant="black" onClick={allowCookies}>
          {t("allow")}
        </Button>
        <Box ml={3}>
          <Button variant="white" onClick={disableCookies}>{t("decline")}</Button>
        </Box>
      </Flex>
    </Container>
  );
};

const Container = styled(Flex)`
  position: fixed;
  left: 0;
  padding: 1.5rem;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: white;
  justify-content: center;
  align-items: center;
  color: black;
`;
