import {
  ApolloClient, ApolloLink,
  HttpLink, InMemoryCache, NormalizedCacheObject
} from "@apollo/client";
import { useMemo } from "react";

const httpLink = new HttpLink({
  uri:
    typeof window === "undefined"
      ? `${process.env.NEXT_PUBLIC_API_URL}/graphql`
      : `/strapi-api/graphql`,
});

let recentLocale: string;
let apolloClient: ApolloClient<NormalizedCacheObject>;
const createApolloClient = (locale: string) => {
  return new ApolloClient({
    ssrMode: typeof window === "undefined", // set to true for SSR
    cache: new InMemoryCache(),
    // TODO add new SentryLink() when https://github.com/DiederikvandenB/apollo-link-sentry/pull/431 is merged
    link: ApolloLink.from([localeMiddleware(locale), httpLink]),
  });
};

const localeMiddleware = (locale: string) =>
  new ApolloLink((operation, forward) => {
    operation.variables = {
      ...operation.variables,
      locale,
    };
    return forward(operation);
  });

export function initializeApollo(
  initialState: NormalizedCacheObject | null,
  locale?: string
) {
  const _apolloClient =
    locale !== recentLocale
      ? createApolloClient(locale || "en")
      : apolloClient || createApolloClient(locale);
  recentLocale = locale || "en";

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;

  apolloClient = _apolloClient;

  return _apolloClient;
}

export const useApollo = (
  initialState: NormalizedCacheObject,
  locale?: string
) => {
  const store = useMemo(
    () => initializeApollo(initialState || null, locale),
    [initialState, locale]
  );
  return store;
};
