import {
  flexbox,
  FlexboxProps,
  space,
  width,
  position,
  maxWidth,
  height,
  display,
  DisplayProps,
  MaxWidthProps,
  PositionProps,
  WidthProps,
  SpaceProps,
  HeightProps,
} from "styled-system";
import styled from "@emotion/styled";

export type Props = FlexboxProps &
  SpaceProps &
  WidthProps &
  MaxWidthProps &
  DisplayProps &
  HeightProps &
  PositionProps;

const Flex = styled("div")<Props>`
  display: flex;
  ${flexbox}
  ${space}
    ${width}
    ${maxWidth}
    ${position}
    ${display}
    ${height}
`;

Flex.displayName = "Flex";

export default Flex;
