import { gql } from "@apollo/client";

export const headerQuery = gql`
  query HeaderQuery($locale: String) {
    header(locale: $locale) {
      menuItems {
        id
        slug
        title
      }
      languageItems {
        id
        title
        value
        image {
          id
          url
        }
      }
    }
  }
`;

export const homePageQuery = gql`
  query HomePagequery($locale: String!) {
    homepage(locale: $locale) {
      seo {
        metaTitle
        metaDescription
      }
      categories {
        id
        slug
        title
        image {
          id
          url
          alternativeText
        }
      }
      activities {
        id
        slug
        title
        order
      }
      presentationTitle
      presentationItems {
        title
        order
      }
      ourGoalTitle
      ourGoalDescription
    }
  }
`;

export const thankYouPageQuery = gql`
  query ThankYouPageQuery($locale: String!) {
    thankYouPage(locale: $locale) {
      text
    }
  }
`;

export const footerQuery = gql`
  query FooterQuery($locale: String) {
    footer(locale: $locale) {
      links {
        title
        url
      }
      socialNetworks {
        url
        image {
          id
          url
          alternativeText
        }
      }
      contacts {
        image {
          id
          url
          alternativeText
        }
        link
        title
        type
      }
      links {
        title
        url
      }
    }
  }
`;

export const categoryDetailQuery = gql`
  query CategoryDetailQuery($id: ID!) {
    category(id: $id) {
      id
      title
      packages {
        id
        slug
        title
        shortDescription
        images {
          id
          url
          alternativeText
        }
        values {
          id
          price {
            amount
            currency
          }
        }
      }
    }
  }
`;

export const packagesQuery = gql`
  query PackagesQuery {
      packages {
        id
        slug
        title
        shortDescription
        images {
          id
          url
          alternativeText
        }
        values {
          id
          price {
            amount
            currency
          }
        }
      }
  }
`;

export const packageDetailQuery = gql`
  query PackageDetailQuery($id: ID!) {
    package(id: $id) {
      id
      title
      slug
      longDescription
      images {
        id
        url
        alternativeText
      }
      values {
        id
        title
        price {
          amount
          currency
        }
        items
        image {
          url
          alternativeText
        }
        activities {
          id
          slug
          order
          title
          minAmountOfPeople
          images {
            id
            url
            alternativeText
          }
        }
      }
      reviews {
        author
        text
      }
    }
  }
`;

export const activityDetailQuery = gql`
  query ActivityDetailQuery($id: ID!) {
    activity(id: $id) {
      id
      title
      slug
      longDescription
      values {
        id
        price {
          amount
          currency
        }
      }
      images {
        id
        url
        alternativeText
      }
      coordinates {
        lat
        lng
      }
      reviews {
        author
        text
      }
      priceType
      minAmountOfPeople
    }
  }
`;

export const activitiesQuery = gql`
  query ActivitiesQuery($filters: [String!]!) {
    activities(where: { _activity_filters: $filters }) {
      id
      slug
      title
      order
      shortDescription
      priceType
      values {
        id
        price {
          amount
          currency
        }
      }
      images {
        id
        url
        alternativeText
      }
    }
  }
`;

export const basketPackagesQuery = gql`
  query BasketPackagesQuery($packageIds: [ID!]!) {
    packages(where: { _id: $packageIds }) {
      id
      slug
      title
      values {
        id
        image {
          id
          url
          alternativeText
        }
        price {
          amount
          currency
        }
        title
        activities {
          id
          title
          order
        }
      }
    }
  }
`;

export const basketActivitiesQuery = gql`
  query BasketActivitiesQuery($activityIds: [ID!]!) {
    activities(where: { _id: $activityIds }) {
      id
      slug
      title
      values {
        id
        price {
          amount
          currency
        }
        title
      }
    }
  }
`;

export const activitiesPageQuery = gql`
  query ActivitiesPageQuery($locale: String!) {
    activitiesPage(locale: $locale) {
      seo {
        metaTitle
        metaDescription
      }
      title
      description
    }
    activityFilters(locale: $locale) {
      id
      title
      type
      icon {
        id
        url
        alternativeText
      }
    }
  }
`;

export const pageQuery = gql`
  query PageBySlugQuery($slug: String!) {
    pageBySlug(slug: $slug) {
      id
      title
      content
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;
