import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

interface Props {
  variant?: "black" | "white";
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
  type?: "button" | "reset" | "submit";
}

const Button = ({
  variant = "black",
  children,
  className,
  ...others
}: Props) => {
  return (
    <CustomButton className={className} variant={variant} {...others}>
      {children}
    </CustomButton>
  );
};

export default Button;

const buttonDefaultStyle = (theme: Theme) => css`
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  padding: 12px 32px;
  background-color: transparent;
  text-transform: uppercase;
  border-width: 1px;
  border-style: solid;
  outline: none;
  user-select: none;
  font-size: ${theme.fontSizes.small};

  :disabled {
    cursor: default;
  }
`;

export const whiteButtonStyle = (theme: Theme) => css`
  ${buttonDefaultStyle(theme)};
  background-color: ${theme.colors.secondary};
  border-color: ${theme.colors.text};
  color: ${theme.colors.text};

  :disabled {
    background-color: #9c9c9c;
  }
`;

export const blackButtonStyle = (theme: Theme) => css`
  ${buttonDefaultStyle(theme)};
  background-color: ${theme.colors.text};
  border-color: ${theme.colors.triatry};
  color: ${theme.colors.secondary};

  :disabled {
    border-color: #9c9c9c;
    color: #9c9c9c;
  }
`;

const CustomButton = styled.button<{ variant?: "black" | "white" }>`
  ${({ theme, variant }) =>
    variant === "black" ? blackButtonStyle(theme) : whiteButtonStyle(theme)}
`;
