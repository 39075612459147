import styled from "@emotion/styled";
import {
  space,
  color,
  width,
  height,
  display,
  DisplayProps,
  HeightProps,
  WidthProps,
  textAlign,
  TextAlignProps,
  flex,
  position,
  SpaceProps,
  ColorProps,
  PositionProps,
  FlexProps,
} from "styled-system";

export type BoxProps =
  | SpaceProps
  | ColorProps
  | PositionProps
  | DisplayProps
  | WidthProps
  | HeightProps
  | FlexProps
  | TextAlignProps;

const Box = styled("div")<BoxProps>(
  position,
  flex,
  space,
  color,
  width,
  textAlign,
  height,
  display
);

Box.displayName = "Box";

export default Box;
