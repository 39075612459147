import { useQuery } from "@apollo/client";
import { useRouter } from "next/router";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { basketActivitiesQuery, basketPackagesQuery } from "../graphql/queries";
import {
  BasketActivitiesQuery,
  BasketActivitiesQueryVariables,
  BasketActivitiesQuery_activities,
  BasketPackagesQuery,
  BasketPackagesQueryVariables,
  BasketPackagesQuery_packages,
  BasketPackagesQuery_packages_values,
  PackageDetailQuery_package_values_image
} from "../graphql/types";
import * as fbq from "../libs/fbpixel";
import { useSettings } from "./SettingsProvider";

interface Price {
  amount?: number | null;
  currency?: string | null;
}
export interface Value {
  id?: string;
  items?: string | null;
  price?: Price | null;
  title?: string | null;
  image?: PackageDetailQuery_package_values_image | null;
}

export interface BasketProduct {
  minAmountOfPeople: number;
  productId?: string;
  productSlug?: string;
  quantity: number;
  title?: string;
  type?: "activity" | "package";
  value?: Value | null;
}

export interface BasketState {
  products: BasketProduct[];
}

const initState = {
  products: [],
};

interface FullBasketProduct extends BasketProduct {
  activities?: { title?: string | null; id?: string | null }[];
}

interface BasketProviderType {
  addProduct: (_: BasketProduct) => void;
  removeProduct: (id: string, valueId: string) => void;
  changeQuantity: (product: BasketProduct, quantity: number) => void;
  totalPrice: number;
  deposit: number;
  products?: FullBasketProduct[] | undefined;
  clearBasket: () => void;
}

export const BasketContext = React.createContext<BasketProviderType>({
  ...initState,
  addProduct: (_: BasketProduct) => {},
  removeProduct: (_: string, __: string) => {},
  changeQuantity: (_: BasketProduct, __: number) => {},
  totalPrice: 0,
  deposit: 0,
  clearBasket: () => {},
});

interface Props {
  children: React.ReactNode;
}

const isSameProduct = (p1: BasketProduct, p2: BasketProduct) =>
  p1.productId === p2.productId && p1.value?.id === p2.value?.id;

const Basket = ({ children }: Props) => {
  const [state, setState] = useState<BasketState>(initState);
  const { currency } = useSettings();
  const { locale } = useRouter();

  const removeProduct = (id: string, valueId: string) => {
    setState((prevState) => ({
      ...prevState,
      products: prevState.products.filter((product) =>
        product.productId === id && valueId === product.value?.id ? false : true
      ),
    }));
    ReactGA.event({
      category: "PRODUCT",
      action: "Product removed from cart",
    });
  };

  const addProduct = (product: BasketProduct) => {
    fbq.event("AddToCart", {
      content_ids: [product.productId],
      content_name: product.title,
      currency: currency,
      value: product.value?.price,
      contents: [
        {
          id: product.productId,
          title: product.title,
          type: product.type,
          quantity: product.quantity,
          price: product.value?.price,
          valueType: product.value?.title,
        },
      ],
      content_type: "product",
    });

    setState((prevState) => {
      const findProduct = prevState.products?.find((p) =>
        isSameProduct(p, product)
      );
      if (findProduct) {
        return {
          ...prevState,
          products: prevState.products?.map((p) =>
            isSameProduct(p, findProduct)
              ? { ...p, quantity: p.quantity + product.quantity }
              : p
          ),
        };
      } else {
        return {
          ...prevState,
          products: [...prevState.products, product],
        };
      }
    });
    ReactGA.event({
      category: "PRODUCT",
      action: "Product added to cart",
    });
  };

  useEffect(() => {
    const cookies = parseCookies();
    if (cookies.locale !== locale && locale) {
      clearBasket();
      setCookie(null, "locale", locale, {
        path: "/",
      });
    }
  }, [locale]);

  const changeQuantity = (product: BasketProduct, quantity: number) => {
    if (quantity > 0) {
      setState((prevState) => ({
        ...prevState,
        products: prevState.products?.map((p) =>
          isSameProduct(p, product) ? { ...p, quantity } : p
        ),
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        products: prevState.products.filter((p) => !isSameProduct(p, product)),
      }));
    }
    ReactGA.event({
      category: "PRODUCT",
      action: "Change product quantity in cart",
    });
  };

  useEffect(() => {
    const cookies = parseCookies();
    const basketCache = cookies.basket;
    if (basketCache !== "undefined" && basketCache !== "null" && basketCache) {
      setState(JSON.parse(basketCache));
    }
  }, []);

  useEffect(() => {
    const correctState = {
      ...state,
      products: state.products.map((p) => ({
        ...p,
        value: {
          ...p.value,
          activities: undefined,
        },
      })),
    };

    setCookie(null, "basket", JSON.stringify(correctState), {
      path: "/",
    });
  }, [state]);

  const clearBasket = useCallback(() => {
    destroyCookie(null, "basket");
    setState(initState);
  }, []);

  const packageIds = state.products
    ?.filter((product) => product.type === "package")
    .map((p) => p.productId || "");
  const { data: packagesData } = useQuery<
    BasketPackagesQuery,
    BasketPackagesQueryVariables
  >(basketPackagesQuery, {
    variables: {
      packageIds,
    },
    skip: packageIds?.length < 1,
  });

  const activityIds = state.products
    ?.filter((product) => product.type === "activity")
    .map((p) => p.productId || "");
  const { data: activitiesData } = useQuery<
    BasketActivitiesQuery,
    BasketActivitiesQueryVariables
  >(basketActivitiesQuery, {
    variables: {
      activityIds,
    },
    skip: activityIds?.length < 1,
  });

  const getCompleteProducts = () =>
    state.products?.map((product) => {
      let foundProduct:
        | BasketPackagesQuery_packages
        | BasketActivitiesQuery_activities
        | null
        | undefined;
      if (product.type === "package") {
        foundProduct = packagesData?.packages?.find(
          (p) => p?.id === product.productId
        );
      } else {
        foundProduct = activitiesData?.activities?.find(
          (a) => a?.id === product.productId
        );
      }

      let activities = undefined;
      if (foundProduct?.__typename === "Package") {
        const value =
          foundProduct?.values?.find(
            (value) => value?.id === product?.value?.id
          ) || product.value;
        activities = (
          value as BasketPackagesQuery_packages_values
        )?.activities?.map((act) => ({
          id: act?.id,
          title: act?.title,
        }));
      }

      return {
        ...product,
        value:
          foundProduct?.values?.find(
            (value) => value?.id === product?.value?.id
          ) || product.value,
        activities,
      };
    });

  const totalPrice = state.products?.reduce(
    (acc, product) =>
      (product?.value?.price?.amount || 0) * (product?.quantity || 0) + acc,
    0
  );

  return (
    <BasketContext.Provider
      value={{
        addProduct,
        removeProduct,
        changeQuantity,
        totalPrice,
        deposit: (50 / 100) * totalPrice,
        products: getCompleteProducts(),
        clearBasket,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};

export default Basket;

export const useBasket = () => {
  const context = useContext(BasketContext);
  return context;
};
