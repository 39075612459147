import { useRouter } from "next/router";
import Script from "next/script";
import React, { useCallback, useContext, useEffect } from "react";
import ReactGA from "react-ga";
import { useLocalStorage } from "../hooks/useLocalStorage";
import * as fbq from "../libs/fbpixel";
import { useBasket } from "./BasketProvider";

if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID, {
    debug: process.env.NODE_ENV === "development",
  });
}

interface Cookies {
  allowed: boolean;
  hideUntil: number;
}

interface AnalyticsProviderType {
  purchaseProducts: (orderId: string) => void;
  allowCookies: () => void;
  disableCookies: () => void;
  cookies: Cookies;
}

export const AnalyticsContext = React.createContext<AnalyticsProviderType>({
  purchaseProducts: (orderId: string) => {},
  allowCookies: () => {},
  cookies: { hideUntil: 0, allowed: false },
  disableCookies: () => {},
});

interface Props {
  children?: React.ReactNode;
}

const AnalyticsProvider = ({ children }: Props) => {
  const { products, totalPrice } = useBasket();
  const router = useRouter();
  const [cookies, setCookiesAgreement] = useLocalStorage<Cookies>(
    "cookiesAgreement",
    {
      hideUntil: 1662034331201,
      allowed: false,
    }
  );

  useEffect(() => {
    (window as any)[
      `ga-disable-${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`
    ] = !cookies.allowed;
  }, [cookies.allowed]);

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();

    const handleRouteChange = (url: string) => {
      fbq.pageview();
      ReactGA.send({ hitType: "pageview", page: url });
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const purchaseProducts = useCallback(
    (orderId: string) => {
      products?.forEach((product) => {
        ReactGA.plugin.execute("ec", "addProduct", {
          id: product.productId,
          name: product.title,
          category: product.type,
          price: product.value?.price,
          quantity: product.quantity,
          currency: "eur",
        });
      });

      ReactGA.plugin.execute("ec", "setAction", "purchase", {
        id: orderId,
        revenue: totalPrice,
        currency: "eur",
      });
    },
    [products, totalPrice]
  );

  const disableCookies = () => {
    const now = new Date().getTime();
    // show cookiesBanner tomorrow again
    setCookiesAgreement({
      allowed: false,
      hideUntil: now + 86400000,
    });
  };

  const allowCookies = () => {
    const now = new Date().getTime();
    setCookiesAgreement({
      hideUntil: now + 31556926000,
      allowed: true,
    });
  };

  return (
    <AnalyticsContext.Provider
      value={{
        purchaseProducts,
        allowCookies,
        cookies,
        disableCookies,
      }}
    >
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />
      {children}
    </AnalyticsContext.Provider>
  );
};
export default AnalyticsProvider;

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  return context;
};
