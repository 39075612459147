import React, { useCallback, useContext, useEffect, useState } from "react";
import { Option } from "../components/form/SelectInput";

interface Company {
  companyId: string;
  companyName: string;
}
export interface User {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  numberPrefix: Option | null;
  street?: string;
  city: string;
  zip: string;
  country: Option | null;
  company?: Company;
}

interface CheckoutState {
  arrivalDate: Date | null;
  departureDate: Date | null;
  order: {
    id: string | null;
    publicId: string | null;
  } | null;
  user: User;
}

const initState: CheckoutState = {
  arrivalDate: null,
  order: null,
  departureDate: null,
  user: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    numberPrefix: null,
    street: "",
    city: "",
    zip: "",
    country: null,
  },
};

interface CheckoutProviderType {
  arrivalDate: null | Date;
  departureDate: null | Date;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    numberPrefix: Option | null;
    street?: string;
    city: string;
    zip: string;
    country: Option | null;
    company?: Company;
  };
  order?: {
    id: string | null;
    publicId: string | null;
  } | null;
  setOrder: (order: { id: string; publicId: string } | null) => void;
  setUser: (user: User) => void;
  clearCheckout: () => void;
  setArrivalDate: (date: Date | null) => void;
  setDepartureDate: (date: Date | null) => void;
}

export const CheckoutContext = React.createContext<CheckoutProviderType>({
  ...initState,
  setOrder: (order: { id: string; publicId: string } | null) => {},
  setArrivalDate: (date: Date | null) => {},
  setDepartureDate: (date: Date | null) => {},
  setUser: (user: User) => {},
  clearCheckout: () => {},
});

interface Props {
  children: React.ReactNode;
}

const Checkout = ({ children }: Props) => {
  const [state, setState] = useState<CheckoutState>(initState);

  const clearCheckout = useCallback(() => {
    setState(initState);
  }, [initState]);

  const setArrivalDate = (date: Date | null) => {
    setState((prevState) => ({
      ...prevState,
      arrivalDate: date,
    }));
  };

  const setDepartureDate = (date: Date | null) => {
    setState((prevState) => ({
      ...prevState,
      departureDate: date,
    }));
  };

  const setOrder = (order: { id: string; publicId: string } | null) => {
    setState((prevState) => ({
      ...prevState,
      order,
    }));
  };

  const setUser = useCallback((user: User) => {
    setState((prevState) => ({
      ...prevState,
      user,
    }));
  }, []);

  useEffect(() => {
    const checkoutCache = localStorage.getItem("checkout");
    if (
      checkoutCache !== "undefined" &&
      checkoutCache !== "null" &&
      checkoutCache
    ) {
      const savedState = JSON.parse(checkoutCache);
      setState({
        ...savedState,
        arrivalDate: savedState?.arrivalDate
          ? new Date(savedState.arrivalDate)
          : null,
        departureDate: savedState?.departureDate
          ? new Date(savedState.departureDate)
          : null,
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("checkout", JSON.stringify(state));
  }, [state]);

  return (
    <CheckoutContext.Provider
      value={{
        ...state,
        setUser,
        clearCheckout,
        setArrivalDate,
        setDepartureDate,
        setOrder
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default Checkout;

export const useCheckout = () => {
  const context = useContext(CheckoutContext);
  return context;
};
