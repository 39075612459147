import { ApolloProvider } from "@apollo/client";
import { Global, ThemeProvider } from "@emotion/react";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import { useRouter } from "next/dist/client/router";
import Head from "next/head";
import Script from "next/script";
import "react-image-lightbox/style.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { CookiesBanner } from "../components/cookies/CookiesBanner";
import { sharedGlobalStyles } from "../GlobalStyles";
import { useApollo } from "../libs/apollo-client";
import theme from "../libs/emotion-theme";
import AnalyticsProvider, {
  useAnalytics,
} from "../providers/AnalyticsProvider";
import BasketProvider from "../providers/BasketProvider";
import CheckoutProvider from "../providers/CheckoutProvider";
import SettingsProvider from "../providers/SettingsProvider";
import "../styles/globals.css";

function MyApp({ Component, pageProps }: AppProps) {
  const { locale } = useRouter();
  const apolloClient = useApollo((pageProps as any).initialApolloState, locale);
  useAnalytics();

  return (
    <ApolloProvider client={apolloClient}>
      <BasketProvider>
        <SettingsProvider>
          <CheckoutProvider>
            <AnalyticsProvider>
              <ThemeProvider theme={theme}>
                <Head>
                  <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                  />
                  <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                  />
                  <link rel="manifest" href="/site.webmanifest" />
                  <link
                    rel="mask-icon"
                    href="/safari-pinned-tab.svg"
                    color="#5bbad5"
                  />
                  <meta name="msapplication-TileColor" content="#ffffff" />
                  <meta name="theme-color" content="#ffffff" />

                  <link rel="preconnect" href="https://fonts.googleapis.com" />
                  <link rel="preconnect" href="https://fonts.gstatic.com" />
                  <link
                    href="https://fonts.googleapis.com/css2?family=Kodchasan:wght@700&display=swap"
                    rel="stylesheet"
                  />
                </Head>
                <Script id="google-tag-manager" strategy="afterInteractive">
                  {`
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
                `}
                </Script>
                <Global styles={sharedGlobalStyles} />
                <Component {...pageProps} />
                <CookiesBanner />
              </ThemeProvider>
            </AnalyticsProvider>
          </CheckoutProvider>
        </SettingsProvider>
      </BasketProvider>
    </ApolloProvider>
  );
}
export default appWithTranslation(MyApp);
