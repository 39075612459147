import { css, Theme } from "@emotion/react";

export const sharedGlobalStyles = (theme: Theme) => css`
  html {
    height: 100%;
  }
  body {
    background-color: #f7f7f7;
    line-height: 1.4;
    height: 100%;
  }

  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  input,
  textarea {
    padding: 9px;
    border: 1px solid ${theme.colors.border};
    border-radius: 0;
    font-size: 16px;
  }

  label {
    text-transform: uppercase;
  }

  button {
    cursor: pointer;
  }
  // third party libraries

  .slick-prev {
    left: -50px;
  }
  .slick-prev:before {
    content: "";
  }
  .slick-next {
    right: -50px;
  }
  .slick-next:before {
    content: "";
  }

  h1 {
    margin: 32px 0;
  }

  .FormGroup {
    will-change: opacity, transform;
    border: 1px solid black;
    color: black;
    padding: 10px;

    text-transform: uppercase;
  }
`;
