export type BreakpointsProp = Array<string> & {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
};

export const breakpoints: BreakpointsProp = [
  "320px",
  "660px",
  "900px",
  "1200px",
];

export const breakpointsNum = [320, 660, 900, 1200];

export const mq = breakpointsNum.map((bp) => `@media (min-width: ${bp}px)`);

export const mx = breakpointsNum.map((bp) => `@media (max-width: ${bp}px)`);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const fontSizes = {
  extraSmall: "0.75rem",
  small: "0.875rem",
  medium: "1rem",
  large: "1.25rem",
  extraLarge: "1.5rem",
};

const colors = {
  primary: "#f2f2f2",
  secondary: "#ffff",
  triatry: "#f7f7f7",
  text: "black",
  danger: "red",
  border: "#d2d2d2",
};

const theme = {
  breakpoints,
  fontSizes,
  lineHeight: 1.6,
  colors,
  borderRadius: 2,
};

export default theme;
