import React, { useContext, useState } from 'react';

interface SettingsProviderType {
  currency: string
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
}

export const SettingsContext = React.createContext<SettingsProviderType>({
  currency: '',
  setCurrency: () => {},
});

interface Props {
  children?: React.ReactNode;
}

const SettingsProvider = ({ children }: Props) => {
  const [currency, setCurrency] = useState("eur");

  const context = {
    currency,
    setCurrency
  };
  return (
    <SettingsContext.Provider value={context}>
      {children}
    </SettingsContext.Provider>
  );
};
export default SettingsProvider;

export const useSettings = () => {
  const context = useContext(SettingsContext);
  return context;
};
